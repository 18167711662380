import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';

import { createRouter, createWebHistory } from 'vue-router' 

//import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import { Ziggy } from './ziggy.js';
import {library} from '@fortawesome/fontawesome-svg-core';


import {
    faRightToBracket, 
    faSearch,
    faDiagramProject,
    faGear,
    faSliders,
    faListCheck,
    faBars,
    faEllipsisVertical,
    faEllipsis,
    faPuzzlePiece,
    faShapes,
    faGears,
    faUsersGear,
    faSave,
    faUndo 
} from '@fortawesome/free-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

library.add(
    faRightToBracket, 
    faSearch,
    faDiagramProject,
    faGear,
    faSliders,
    faListCheck,
    faBars,
    faEllipsisVertical,
    faEllipsis,
    faPuzzlePiece,
    faShapes,
    faGears,
    faUsersGear,
    faSave,
    faUndo 
    );

import axios from 'axios'
import VueAxios from 'vue-axios'


axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}


window.Laravel = { csrfToken: document.head.querySelector("[name=csrf-token]").content }

import moment from 'moment';

//import {store} from '@/Store/store.js';

const appName = import.meta.env.VITE_APP_NAME || 'Vendor Portal';
//const storeComposable = store();

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
         //   .provide('store', storeComposable)
 //           .use(ElementPlus)
            .use(moment)
            .use(VueAxios, axios)
            .component('font-awesome-icon', FontAwesomeIcon)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
